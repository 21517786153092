class Menu {
    menu: any;
    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        $(window).resize(this.destroy.bind(this));
        $(window).resize(this.height.bind(this));
        this.height();
    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');
    }
    destroy() {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    height() {
        if ($(window).width() >= 1024) {
            this.menu = $('.c-nav').innerHeight();
           $('body').css({"padding-top": `${this.menu - 0}px`});
        } else if ($(window).width() <= 1024) {
            $('body').css({"padding-top": `0px`});
        }
    }
}
let menu = new Menu();
